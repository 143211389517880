import React, { Fragment, useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import Metadata from '../layout/metadata';

import { createOrder, clearErrors } from '../../actions/orderActions'

import { emptyCart } from '../../actions/cartActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let usedFinger = "";
const MATCH_THRESHOLD = 100;
let foundFinger = false;

const SignModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let [match, setMatch] = useState("None");

    const loggedInUser = useSelector(state => state.auth).user;

    const {loading, error, success} = useSelector(state => state.modifyOrder)

    useEffect(() => {
        if (error) {
            toast.error("Error creating order, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
        
        if (success) {
            toast.success("Application Successful, navigate to Loans in your dashboard to view status of your loan.")
            navigate('/checkout/success')
        }
    }, [dispatch, error, success])

    function successFunc(result) {
        if (result.ErrorCode === 0) {
            if (result != null && result.BMPBase64.length > 0) {
                document.getElementById("sign").src = "data:image/bmp;base64," + result.BMPBase64;
                return result.TemplateBase64;
            }
        }else if (result.ErrorCode === 3) {
            return toast.error("Please connect a compatible SecuGen fingerprint scanner and try again.");
        } else {
            return toast.error("Fingerprint Capture Error Code:  " + result.ErrorCode);
        }
    };

    function errorFunc(status) {
        document.getElementById("sign").src = "/images/PlaceFinger.bmp";
        return toast.error("Error collecting fingerprint. Check if driver is installed and service is running.");
    };

    function CallSGIFPGetData(successCall, failCall) {

        document.getElementById("sign").src = "/images/wait.gif";
        setMatch("Checking")

        let uri = "https://localhost:8443/SGIFPCapture";
        // let uri 	= "https://market.farmwarehouse.ng:8443/SGIFPCapture";
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {

            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                let fpobject = JSON.parse(xmlhttp.responseText);
                
                if (fpobject.ErrorCode !== 0) {
                    return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.")
                };

                successCall(fpobject);

                for (let fingerPrint of loggedInUser.fingerPrints) {
                    if (!foundFinger) matchScore(fpobject.TemplateBase64, fingerPrint.TemplateBase64, fingerPrint.fpos, succMatch, failMatch);
                }
                
                return fpobject.TemplateBase64;
            }
            else if (xmlhttp.status == 404) {
                failCall(xmlhttp.status)
            }
        }
        xmlhttp.onerror = function () {
            failCall(xmlhttp.status);
        }
        let params = "timeout=" + "10000";
        params += "&quality=" + "50";
        params += "&templateFormat=" + "ISO";
        xmlhttp.open("POST", uri, true);
        xmlhttp.send(params);
    };

    function matchScore(template_1, template_2, finger, succFunction, failFunction) {
        if (!template_1 || !template_2) {
            toast.error("Please provide two scans!");
            return;
        };

        const uri = "https://localhost:8443/SGIMatchScore";

        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                return succFunction(JSON.parse(xmlhttp.responseText), finger);
            } else if (xmlhttp.status == 404) {
                return failFunction(xmlhttp.status)
            };
        }

        xmlhttp.onerror = function () {
            failFunction(xmlhttp.status);
        }
        let params = "template1=" + encodeURIComponent(template_1);
        params += "&template2=" + encodeURIComponent(template_2);
        params += "&templateFormat=" + "ISO"; // DEFAULT IS ISO
        xmlhttp.open("POST", uri, false);
        xmlhttp.send(params);
    };

    function succMatch(result, finger) {
        usedFinger = finger;
        if (result.ErrorCode == 0) {
            if (result.MatchingScore >= MATCH_THRESHOLD) {
                foundFinger = true;
                setMatch("Match Found");
            } else {
                setMatch("No Match Found");
            }
        }
        else {
            toast.error("Error Matching Fingerprint. ErrorCode = " + result.ErrorCode);
            return false;
        }
    }

    function failMatch(error) {
        toast.error("Failed to verify fingerprint, please contact admin.");
    }

    function submitOnClick() {
        const { loanData, orderDetails } = location.state;

        const { cartItems, shippingInfo, paymentMethod } = orderDetails;
      
        const order = {
            orderItems: cartItems,
            shippingInfo,
            paymentMethod
        }
    
        const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));

        if (orderInfo) {
            order.itemsPrice = orderInfo.itemsPrice
            order.shippingPrice = orderInfo.shippingPrice
            order.taxPrice = orderInfo.taxPrice
            order.totalPrice = orderInfo.totalPrice
            order.financeStatus = "Processing"
        }

        dispatch(emptyCart())
        dispatch(createOrder({orderData: order, loanData: loanData}))

    }

    return (
        <Fragment>
            <Metadata title={'Sign'} />
            <ToastContainer />
            {/* Modal */}
            <div className="modal fade" id="signModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="signModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <span className="modal-title text-size-sm bold-2" id="signModalLabel">Biometric Signature</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                        {loggedInUser.fingerPrints.length >= 0 ? // REVERT TO > 0
                            <div className="d-flex flex-column align-items-center m-5 p-4">
                                <p className="text-center">When you're ready, click "Scan Finger" and scan any of your registered fingers</p>
                                <img id="sign" alt="finger" className="d-block hand" src="/images/PlaceFinger.bmp"/>

                                {match === "Match Found" || loggedInUser.tempPermission ? 
                                    <button onClick={submitOnClick} className="green-btn mt-4" type="button" disabled={loading ? true : false}>{loading ? "Creating Order & submitting application..." : "Submit Application"}</button>
                                    :   <button className="border-btn mt-4" type="button" onClick={function() { CallSGIFPGetData(successFunc, errorFunc)}}>Scan Finger</button> 
                                    }
                                
                                
                                
                            </div>
                        :   <p className="text-size-sm text-center p-5">You do not have any registered fingerprints and therefore cannot sign, please register your biometrics with the admin and try again.</p> }
                            
                            <div className="text-center">
                                {match === "Match Found" ? <i className="text-size-xs green-color bold-2">Match found! ({usedFinger})</i>
                                : match === "No Match Found" ? <i className="text-size-xs red-color bold-2">No match found!</i> : <Fragment></Fragment>}
                            </div>

                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                       
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default SignModal;
