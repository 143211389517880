import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/route/ProtectedRoute';

//LAYOUT
import Header from './components/layout/header'
import Footer from './components/layout/footer'
// USERS
import Register from './components/user/register'
import RegisterBuyer from './components/user/registerBuyer'
import RegisterFarmer from './components/user/registerFarmer'
import RegisterFarmerFarm from './components/user/newFarmDetails'
import RegisterVendor from './components/user/registerVendor'
import Signin from './components/user/signin'
import Welcome from './components/user/welcome'
import ChangePassword from './components/user/changePassword'
import ForgotPassword from './components/user/forgotPassword'
import ResetPassword from './components/user/resetPassword'
// PRODUCTS
import Home from './components/home'
import ProductCategory from './components/product/productCategory';
import ProductDetails from './components/product/productDetails'
// CART
import Cart from './components/cart/cart'
import Shipping from './components/cart/shipping'
import ConfirmOrder from './components/cart/confirmOrder'
import Payment from './components/cart/payment'
import OrderSuccess from './components/cart/orderSuccess'
// BUYER
import BuyerOrders from './components/order/buyerOrders'
// SELLER
import SellerDashboard from './components/seller/sellerDashboard'
import SellerInventory from './components/seller/sellerInventory'
import SellerPayments from './components/seller/sellerPayments'
import SellerOrders from './components/seller/sellerOrders'
import SellerLoans from './components/seller/sellerLoans'
import ApplyForLoan from './components/seller/applyForLoan'
import ApplyForFinancing from './components/seller/applyForFinancing'
import LoanOffers from './components/seller/loanOffers'
import LoanTerms from './components/seller/loanTerms'
import SellerLoanDetails from './components/seller/sellerLoanDetails'

import SellerInsurance from './components/seller/sellerInsurance'
import InsuranceOffers from './components/seller/insuranceOffers'
import AddProduct from './components/seller/addProduct'
import UpdateProduct from './components/seller/updateProduct'
import SellerOrderDetails from './components/seller/sellerOrderDetails'
import FarmDetails from './components/farm/farmDetails';
// import SellerPayments from './components/seller/sellerpayments'
import SellerStorage from './components/seller/sellerStorage'
// ADMIN
import AdminDashboard from './components/admin/adminDashboard'
import AllOrders from './components/admin/allOrders'
import AdminOrderDetails from './components/admin/adminOrderDetails'
import AllProducts from './components/admin/allProducts'
// import AdminAddProduct from './components/admin/adminAddProduct';
import AdminPayments from './components/admin/adminPayments'
import AllStorage from './components/admin/allStorage'
import AllLogs from './components/admin/allLogs'
import AllRequests from './components/admin/allRequests'
import AllUsers from './components/admin/allUsers'
import AllFarms from './components/admin/allFarms';
import AllLoans from './components/admin/allLoans';
import AdminLoanDetails from './components/admin/adminLoanDetails'
import AllInsurance from './components/admin/allInsurance';
import AdminFarmDetails from './components/admin/adminFarmDetails';
import StoreProduct from './components/admin/storeProduct';
import AdminStorageDetails from './components/admin/adminStorageDetails';
import AdminRequestDetails from './components/admin/adminRequestDetails';

import UserDetails from './components/user/userDetails'
import UserProfile from './components/user/userProfile'

// ORDER
import OrderDetails from './components/order/orderDetails'

// REQUEST
import RequestProduct from './components/request/requestProduct';
import UserRequests from './components/request/userRequests'
import RequestDetails from './components/request/requestDetails'

// NOTIFICATION
import Notification from './components/notification/notifications';

// PRINTABLES
import PrintAgreement from './components/printables/agreement';
import PrintLoanSummary from './components/printables/loanSummary';


import { loadUser } from './actions/userActions'
import store from './store'
import axios from 'axios';



function App() {
  const [paystackPK, setPaystackPK] = useState("");

  useEffect(() => {
    store.dispatch(loadUser())

    async function getPaystackKeys() {
      const { data } = await axios.get('/api/v1/paystack');
      setPaystackPK(data.paystackPK)
    }

    getPaystackKeys();
}, [])

  return (
    <Router>
      <div className="App">
      <Header/>
          <Routes>
            {/* USER */}
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/buyer" element={<RegisterBuyer />} />
            <Route path="/register/farmer" element={<RegisterFarmer />} />
            <Route path="/register/farmer/farm-details" element={<RegisterFarmerFarm />} />
            <Route path="/register/vendor" element={<RegisterVendor />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/verify/:confirmationCode" element={<Welcome />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />

            <Route path="/password/change" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />

            {/* REQUESTS */}
            <Route path="/requests" element={<ProtectedRoute><UserRequests /></ProtectedRoute>} />
            <Route path="/requests/new" element={<ProtectedRoute><RequestProduct /></ProtectedRoute>} />
            <Route path="/requests/:id" element={<ProtectedRoute><RequestDetails /></ProtectedRoute>} />
            <Route path="/farms/:id" element={<ProtectedRoute><FarmDetails /></ProtectedRoute>} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/orders" element={<ProtectedRoute><BuyerOrders /></ProtectedRoute>} />
            <Route path="/orders/:id" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
            <Route path="/checkout/shipping" element={<ProtectedRoute><Shipping /></ProtectedRoute>} />
            <Route path="/checkout/confirm-order" element={<ProtectedRoute><ConfirmOrder /></ProtectedRoute>} />
            <Route path="/checkout/payment" element={<ProtectedRoute><Payment paystackPK={paystackPK}/></ProtectedRoute>} />
            <Route path="/checkout/success" element={<ProtectedRoute><OrderSuccess /></ProtectedRoute>} />
            {/* SELLER */}
            <Route path="/seller/dashboard" element={<ProtectedRoute isSeller={true}><SellerDashboard /></ProtectedRoute>} />
            <Route path="/seller/inventory" element={<ProtectedRoute isSeller={true}><SellerInventory /></ProtectedRoute>} />
            <Route path="/seller/payments" element={<ProtectedRoute isSeller={true}><SellerPayments /></ProtectedRoute>} />
            <Route path="/seller/orders" element={<ProtectedRoute isSeller={true}><SellerOrders /></ProtectedRoute>} />
            <Route path="/seller/loans" element={<ProtectedRoute isSeller={true}><SellerLoans /></ProtectedRoute>} />
            <Route path="/seller/loans/apply" element={<ProtectedRoute isSeller={true}><ApplyForFinancing /></ProtectedRoute>} />
            <Route path="/seller/loans/:id" element={<ProtectedRoute isSeller={true}><SellerLoanDetails paystackPK={paystackPK}/></ProtectedRoute>} />
            {/* <Route path="/seller/financing/apply" element={<ProtectedRoute isSeller={true}>< /></ProtectedRoute>} /> */}
            <Route path="/seller/loans/apply/terms" element={<ProtectedRoute isSeller={true}><LoanTerms /></ProtectedRoute>} />
            <Route path="/seller/loans/offers" element={<ProtectedRoute isSeller={true}><LoanOffers /></ProtectedRoute>} />

            <Route path="/seller/insurance" element={<ProtectedRoute isSeller={true}><SellerInsurance /></ProtectedRoute>} />
            <Route path="/seller/insurance/offers" element={<ProtectedRoute isSeller={true}><InsuranceOffers /></ProtectedRoute>} />
            {/* <Route path="/seller/payments" element={<SellerPayments />} /> //PROTECT */}
            <Route path="/seller/storage" element={<ProtectedRoute isSeller={true}><SellerStorage /></ProtectedRoute>} />
            <Route path="/seller/add-product" element={<ProtectedRoute isSeller={true}><AddProduct /></ProtectedRoute>} />
            <Route path="/seller/update-product/:id" element={<ProtectedRoute isSeller={true}><UpdateProduct /></ProtectedRoute>} />
            <Route path="/seller/orders/:id" element={<ProtectedRoute isSeller={true}><SellerOrderDetails /></ProtectedRoute>} />
            {/* ADMIN */}
            <Route path="/admin/dashboard" element={<ProtectedRoute isAdmin={true}><AdminDashboard /></ProtectedRoute>} />
            <Route path="/admin/orders" element={<ProtectedRoute isAdmin={true}><AllOrders /></ProtectedRoute>} />
            <Route path="/admin/orders/:id" element={<ProtectedRoute isAdmin={true}><AdminOrderDetails /></ProtectedRoute>} />
            <Route path="/admin/products" element={<ProtectedRoute isAdmin={true}><AllProducts /></ProtectedRoute>} />
            {/* <Route path="/admin/products/add-product" element={<ProtectedRoute isAdmin={true}><AdminAddProduct /></ProtectedRoute>} /> */}
            <Route path="/admin/payments" element={<ProtectedRoute isAdmin={true}><AdminPayments /></ProtectedRoute>} />
            <Route path="/admin/storage" element={<ProtectedRoute isAdmin={true}><AllStorage /></ProtectedRoute>} />
            <Route path="/admin/storage/:id" element={<ProtectedRoute isAdmin={true}><AdminStorageDetails /></ProtectedRoute>} />
            <Route path="/admin/storage/add-product" element={<ProtectedRoute isAdmin={true}><StoreProduct /></ProtectedRoute>} />
            <Route path="/admin/requests/:id" element={<ProtectedRoute isAdmin={true}><AdminRequestDetails /></ProtectedRoute>} />
            <Route path="/admin/requests" element={<ProtectedRoute isAdmin={true}><AllRequests /></ProtectedRoute>} />
            <Route path="/admin/logs" element={<ProtectedRoute isAdmin={true}><AllLogs /></ProtectedRoute>} />
            <Route path="/admin/users" element={<ProtectedRoute isAdmin={true}><AllUsers /></ProtectedRoute>} />
            <Route path="/admin/users/:id" element={<ProtectedRoute isAdmin={true}><UserDetails /></ProtectedRoute>} />
            <Route path="/admin/farms" element={<ProtectedRoute isAdmin={true}><AllFarms /></ProtectedRoute>} />
            <Route path="/admin/farms/:id" element={<ProtectedRoute isAdmin={true}><AdminFarmDetails /></ProtectedRoute>} />
            <Route path="/admin/loans" element={<ProtectedRoute isAdmin={true}><AllLoans /></ProtectedRoute>} />
            <Route path="/admin/loans/:id" element={<ProtectedRoute isAdmin={true}><AdminLoanDetails /></ProtectedRoute>} />
            <Route path="/admin/insurance" element={<ProtectedRoute isAdmin={true}><AllInsurance /></ProtectedRoute>} />
            
            <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/users/:id" element={<UserDetails />} /> (//PROTECT)

            <Route path="/products/:category/:id" element={<ProductDetails />} />
            <Route path="/products/:category" element={<ProductCategory />} />

            {/* NOTIFICATION */}
            <Route path="/notifications" element={<ProtectedRoute><Notification /></ProtectedRoute>} />

            {/* PRINTABLES */}
            <Route path="/admin/loans/:id/print" element={<ProtectedRoute isAdmin={true}><PrintAgreement /></ProtectedRoute>} />
            <Route path="/admin/loans/:id/summary" element={<ProtectedRoute isAdmin={true}><PrintLoanSummary /></ProtectedRoute>} />
          </Routes>
        <Footer/>
       
      </div>  
    </Router>
  );
}

export default App;